var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return "		<div class=\"evaluations-nav\">\n			<a class=\"header-link back-link\" style=\"display: none;\" data-behavior=\"detail-close\"\n				href=\"/courses/"
    + alias1(container.lambda((depth0 != null ? depth0.courseId : depth0), depth0))
    + "/"
    + alias1(__default(require("../../helpers/to_lower.js")).call(alias2,(depth0 != null ? depth0.creatorType : depth0),{"name":"to_lower","hash":{},"data":data}))
    + "s/evaluations\">Back to Evaluations</a>\n\n			<div class=\"creator-links\">\n"
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.prevCreator : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(4, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "\n				<div class=\"dropdown dropdown-button\" data-behavior=\"go-to-creator\">\n					<a class=\"dropdown--toggle\" data-behavior=\"toggle\" href=\"#\">Go to...</a>\n					<div class=\"dropdown--options\">\n						<ul>\n"
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.isGroupProject : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.program(9, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "						</ul>\n					</div>\n				</div>\n\n"
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.nextCreator : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.program(13, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "			</div>\n		</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "					<a class=\"header-link prev-link\"\n						href=\"/courses/"
    + alias2(alias1((depth0 != null ? depth0.courseId : depth0), depth0))
    + "/"
    + alias2(__default(require("../../helpers/to_lower.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.creatorType : depth0),{"name":"to_lower","hash":{},"data":data}))
    + "s/evaluations/creator/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.prevCreator : depth0)) != null ? stack1.attributes : stack1)) != null ? stack1.id : stack1), depth0))
    + "/project/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.project : depth0)) != null ? stack1.id : stack1), depth0))
    + "\">Previous</a>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "					<a class=\"header-link prev-link disabled\"\n						href=\"/courses/"
    + alias2(alias1((depth0 != null ? depth0.courseId : depth0), depth0))
    + "/"
    + alias2(__default(require("../../helpers/to_lower.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.creatorType : depth0),{"name":"to_lower","hash":{},"data":data}))
    + "s/evaluations/creator/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.prevCreator : depth0)) != null ? stack1.attributes : stack1)) != null ? stack1.id : stack1), depth0))
    + "/project/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.project : depth0)) != null ? stack1.id : stack1), depth0))
    + "\">Previous</a>\n";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.groups : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "									<li><a href=\"/courses/"
    + alias2(alias1((depths[1] != null ? depths[1].courseId : depths[1]), depth0))
    + "/"
    + alias2(__default(require("../../helpers/to_lower.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? depths[1].creatorType : depths[1]),{"name":"to_lower","hash":{},"data":data}))
    + "s/evaluations/creator/"
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "/project/"
    + alias2(alias1(((stack1 = (depths[1] != null ? depths[1].project : depths[1])) != null ? stack1.id : stack1), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "</a></li>\n";
},"9":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.creators : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "				<a class=\"header-link next-link\"\n					href=\"/courses/"
    + alias2(alias1((depth0 != null ? depth0.courseId : depth0), depth0))
    + "/"
    + alias2(__default(require("../../helpers/to_lower.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.creatorType : depth0),{"name":"to_lower","hash":{},"data":data}))
    + "s/evaluations/creator/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.nextCreator : depth0)) != null ? stack1.attributes : stack1)) != null ? stack1.id : stack1), depth0))
    + "/project/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.project : depth0)) != null ? stack1.id : stack1), depth0))
    + "\">Next</a>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "					<a class=\"header-link next-link disabled\"\n					href=\"/courses/"
    + alias2(alias1((depth0 != null ? depth0.courseId : depth0), depth0))
    + "/"
    + alias2(__default(require("../../helpers/to_lower.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.creatorType : depth0),{"name":"to_lower","hash":{},"data":data}))
    + "s/evaluations/creator/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.nextCreator : depth0)) != null ? stack1.attributes : stack1)) != null ? stack1.id : stack1), depth0))
    + "/project/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.project : depth0)) != null ? stack1.id : stack1), depth0))
    + "\">Next</a>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "            <i class=\"icon people\"></i>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "			<span class=\"badge\">Due "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.project : depth0)) != null ? stack1.due_date : stack1), depth0))
    + "</span>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "			<a data-behavior=\"open-group-modal\" href=\"#\">\n				"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.creator : depth0)) != null ? stack1.name : stack1), depth0))
    + "\n			</a>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "			"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.creator : depth0)) != null ? stack1.name : stack1), depth0))
    + "\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "			<a href=\"#\" class=\"outline-button\" data-behavior=\"open-project-description\">Show Project Description</a>\n";
},"25":function(container,depth0,helpers,partials,data) {
    return "			<a href=\"#\" class=\"outline-button\" data-behavior=\"show-rubric\">Show Rubric</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"page-content--heading\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showCreatorNav : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n	<h2 class=\"heading-primary header\">\n		"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.project : depth0)) != null ? stack1.name : stack1), depth0))
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isGroupProject : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.project : depth0)) != null ? stack1.due_date : stack1),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	</h2>\n\n	<h2 class=\"heading-secondary\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isGroupProject : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.program(21, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "	</h2>\n\n	<div class=\"heading-utility\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.project : depth0)) != null ? stack1.description : stack1),{"name":"if","hash":{},"fn":container.program(23, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.projectEvaluatable : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	</div>\n</div>\n\n<div class=\"submission-section\">\n	<div data-region=\"flash\" class=\"flash\"></div>\n  <div data-region=\"project-assets\"></div>\n	<div data-region=\"submission-assets\"></div>\n	<div data-region=\"submission-evaluations\"></div>\n	<div data-region=\"submission-discussion\"></div>\n	<div data-region=\"submission-utility\"></div>\n</div>\n";
},"useData":true,"useDepths":true});