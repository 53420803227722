var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "			<ul class=\"horizontal-links\">\n\n				<li>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.parent_id : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "				</li>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.current_user_can_destroy : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			</ul>\n\n			<div class=\"alert alert-warning\" data-behavior=\"delete-confirm\" style=\"display: none;\">\n				<aside class=\"alert--label\" data-behavior=\"alert-label\"></aside>\n				<p>\n					Are you sure?\n				</p>\n\n				<p>\n					<a data-behavior=\"delete\" data-post=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.id : depth0), depth0))
    + "\" href=\"#\">Yes. Delete this post. </a>\n				</p>\n\n				<p>\n					<a class=\"alert--close\" data-behavior=\"toggle-delete-confirm\" href=\"#\">On second thought, no.</a>\n				</p>\n			</div>\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "						<a class=\"reply-link primary-link\" data-behavior=\"toggle-reply\" data-post=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.parent_id : depth0), depth0))
    + "\"\n						   href=\"#\">Reply</a>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "						<a class=\"reply-link primary-link\" data-behavior=\"toggle-reply\" data-post=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.id : depth0), depth0))
    + "\" href=\"#\">Reply</a>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "					<li>\n						<a class=\"delete-link secondary-link\" data-behavior=\"toggle-delete-confirm\" href=\"#\">Delete</a>\n					</li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<div class=\"discussion-list--item\">\n	<div class=\"discussion-list--avatar \">\n		<img src=\""
    + alias2(alias1((depth0 != null ? depth0.gravatar : depth0), depth0))
    + "\" alt=\"\"/>\n	</div>\n	<div class=\"discussion-list--body\">\n		<header class=\"discussion-list--header\">\n			<h3 class=\"discussion-list--name\">"
    + alias2(alias1((depth0 != null ? depth0.author_name : depth0), depth0))
    + "</h3>\n			<time class=\"discussion-list--timestamp\" datetime=\"\">Posted "
    + alias2(alias1((depth0 != null ? depth0.month : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? depth0.day : depth0), depth0))
    + ", "
    + alias2(alias1((depth0 != null ? depth0.year : depth0), depth0))
    + " at "
    + alias2(alias1((depth0 != null ? depth0.time : depth0), depth0))
    + "</time>\n		</header>\n\n		<div class=\"markdown-content\">\n			"
    + ((stack1 = alias1((depth0 != null ? depth0.body : depth0), depth0)) != null ? stack1 : "")
    + "\n		</div>\n\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.current_user_can_reply : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n		<div class=\"discussion-list--input-container\" data-behavior=\"input-container\" style=\"display: none\">\n			<div data-container=\"flash\"></div>\n"
    + ((stack1 = container.invokePartial(require("../partials/discussionPostInput.hbs"),depth0,{"name":"../partials/discussionPostInput","data":data,"indent":"\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "		</div>\n\n		<div class=\"rule\"></div>\n	</div>\n	<!-- /discussion-list--body -->\n</div> <!-- /discussion-list--item -->\n\n\n<ul class=\"discussion-list\" data-behavior=\"post-container\">\n</ul>\n";
},"usePartial":true,"useData":true});