var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "		<li data-behavior=\"placard-trigger\">\n"
    + ((stack1 = container.invokePartial(require("../../partials/range.hbs"),depth0,{"name":"range","hash":{"range_class":"range-tertiary","placard_desc":(depth0 != null ? depth0.desc : depth0),"placard_title":(depth0 != null ? depth0.range : depth0),"placard":true,"percentage":(depth0 != null ? depth0.percentage : depth0),"possible":(depth0 != null ? depth0.high : depth0),"score":(depth0 != null ? depth0.score : depth0),"title":(depth0 != null ? depth0.name : depth0)},"data":data,"indent":"\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "		</li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<div data-behavior=\"toggle-children\">\n"
    + ((stack1 = container.invokePartial(require("../../partials/range.hbs"),depth0,{"name":"range","hash":{"range_class":"range-secondary range-expandable","placard":false,"percentage":(depth0 != null ? depth0.percentage : depth0),"title":(depth0 != null ? depth0.title : depth0)},"data":data,"indent":"\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n\n<ul class=\"evaluation-single--details evaluations-hidden\" data-behavior=\"child-container\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.score_details : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	<li data-behavior=\"total\">\n		<div class=\"range-tertiary range-editable range-total\">\n			<div class=\"range--track-wrapper\">\n				<h4 class=\"range-total--label\">Total Score</h4>\n\n				<div class=\"range--handle\">\n					<div class=\"score-wrapper\">\n						<span class=\"score\" data-container=\"total-score\">"
    + alias2(alias1((depth0 != null ? depth0.total_score : depth0), depth0))
    + "</span>\n						<span class=\"slash\"></span>\n						<span class=\"possible\">"
    + alias2(alias1((depth0 != null ? depth0.points_possible : depth0), depth0))
    + "</span>\n					</div>\n				</div>\n			</div>\n		</div>\n	</li>\n</ul>\n";
},"usePartial":true,"useData":true});