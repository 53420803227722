var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"page-section-spacing-top page-section\">\n	<div class=\"header-wrapper\">\n		<div class=\"buttons\">\n		</div>\n		<div class=\"header\"><h2>Manage Submission</h2></div>\n	</div>\n\n	<div class=\"body\" data-behavior=\"body\">\n		<a href=\"/courses/"
    + alias4(((helper = (helper = helpers.courseId || (depth0 != null ? depth0.courseId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"courseId","hash":{},"data":data}) : helper)))
    + "/submissions/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "/reassign\" class=\"primary-button next-button\">Reassign\n			Submission</a>\n		<a href=\"/courses/"
    + alias4(((helper = (helper = helpers.courseId || (depth0 != null ? depth0.courseId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"courseId","hash":{},"data":data}) : helper)))
    + "/submissions/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "/destroy_confirm\" data-behavior=\"delete\"\n		   class=\"warning-button delete-button\">Delete Submission</a>\n	</div>\n</div>\n\n";
},"useData":true});