var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<h1><i class=\"icon person\"></i>INDIVIDUAL WORK</h1>";
},"3":function(container,depth0,helpers,partials,data) {
    return "<h1><i class=\"icon people\"></i>GROUP WORK</h1>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div data-region=\"flash\" class=\"flash-fullbleed\"></div>\n<div class=\"page-section page-section-clear\">\n	<div class=\"header-wrapper\">\n		<div class=\"header\">\n            "
    + ((stack1 = __default(require("../../helpers/if_match.js")).call(alias1,(depth0 != null ? depth0.creatorType : depth0),"User",{"name":"if_match","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = __default(require("../../helpers/if_match.js")).call(alias1,(depth0 != null ? depth0.creatorType : depth0),"Group",{"name":"if_match","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n		</div>\n	</div>\n</div>\n<div class=\"page-section\" data-region=\"filters\"></div> \n<div class=\"page-section page-section-clear\">\n	<div class=\"body\">\n		<div class=\"matrix-warning\" data-region=\"warning\"></div>\n		<div data-behavior=\"hide-on-warning\">\n		<div data-behavior=\"matrix-slider\" class=\"matrix-wrapper\">\n			<button data-behavior=\"matrix-slider-left\"\n					class=\"matrix-cells-wrapper--back\"></button>\n			<button data-behavior=\"matrix-slider-right\"\n					class=\"matrix-cells-wrapper--forward\"></button>\n\n			<div class=\"matrix-wrapper--top\">\n				<div class=\"matrix-wrapper--top-left-cell\" data-behavior=\"top-left-cell\" data-match-height-target></div>\n				<div class=\"matrix-wrapper--columns\">\n					<table class=\"table matrix matrix-col-headers\" data-behavior=\"col-headers\" data-region=\"projects\">\n					</table>\n				</div>\n			</div>\n\n			<div class=\"matrix-wrapper--bottom\">\n\n				<div class=\"matrix-wrapper--left\">\n					<div class=\"matrix-wrapper--creators\" data-vertical-headers data-region=\"creators\"></div>\n				</div>\n\n				<div class=\"matrix-wrapper--body\" data-behavior=\"matrix-stage\">\n					<div class=\"matrix-body\" data-behavior=\"matrix-actor\">\n						<div class=\"matrix-wrapper--cells\">\n							<table class=\"table matrix matrix-cells\" data-region=\"matrix\">\n							</table>\n						</div>\n					</div>\n				</div>\n\n			</div>\n		</div>\n		</div>\n	</div>\n</div>\n\n";
},"useData":true});