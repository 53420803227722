var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "		<p class=\"small\">If you would like to report this error to your Vocat support team, please include this error code:</p>\n		<p class=\"small error-code\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.errorDetails : depth0)) != null ? stack1.code : stack1), depth0))
    + "</p>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"alert alert-error\">\n	<p>Vocat has experienced a client-side error.</p>\n\n	<p class=\"small\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.errorDetails : depth0)) != null ? stack1.description : stack1), depth0))
    + "</p>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.errorDetails : depth0)) != null ? stack1.code : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});