var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<form class=\"formgrid\">\n	<div class=\"formgrid whole\">\n		<p>"
    + alias4(((helper = (helper = helpers.label || (depth0 != null ? depth0.label : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data}) : helper)))
    + "</p>\n		<textarea rows=\"5\" data-property=\"input\" name=\"input\">"
    + alias4(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data}) : helper)))
    + "</textarea>\n\n		<div class=\"alert alert-error\" data-behavior=\"error-container\" style=\"display: none;\"></div>\n	</div>\n	<div class=\"formgrid whole\">\n		<button class=\"primary-button "
    + alias4(((helper = (helper = helpers.saveClasses || (depth0 != null ? depth0.saveClasses : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"saveClasses","hash":{},"data":data}) : helper)))
    + "\" data-behavior=\"model-save\">"
    + alias4(((helper = (helper = helpers.saveLabel || (depth0 != null ? depth0.saveLabel : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"saveLabel","hash":{},"data":data}) : helper)))
    + "</button>\n		<button class=\"warning-button cancel-button\" data-behavior=\"cancel\">Cancel</button>\n	</div>\n</form>\n\n";
},"useData":true});