var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "switch-checked";
},"3":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<li data-model-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"evaluation-collection evaluation-collection-instructor\">\n	<div data-behavior=\"toggle-children\">\n		<div data-behavior=\"range\" class=\"range range-expandable\">\n			<h4 class=\"range--title\">\n				Your Evaluation\n			</h4>\n\n			<div class=\"range--track\">\n				<span data-behavior=\"range-fill\" style=\"width: "
    + alias4(((helper = (helper = helpers.total_percentage_rounded || (depth0 != null ? depth0.total_percentage_rounded : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"total_percentage_rounded","hash":{},"data":data}) : helper)))
    + "%\"\n					  class=\"range--fill\"></span>\n\n				<div class=\"range--handle\"><b></b><span data-container=\"percentage\">"
    + alias4(((helper = (helper = helpers.total_percentage_rounded || (depth0 != null ? depth0.total_percentage_rounded : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"total_percentage_rounded","hash":{},"data":data}) : helper)))
    + "</span>%\n				</div>\n			</div>\n		</div>\n	</div>\n	<ul class=\"evaluation-single--details evaluations-hidden\" data-behavior=\"child-container\">\n		<!-- criteria will be inserted here -->\n		<li data-anchor=\"child-insert-before\" data-behavior=\"subtotal\">\n			<div class=\"range-tertiary range-editable range-total\">\n				<div class=\"range--track-wrapper\">\n					<h4 class=\"range-total--label\">Rubric Total</h4>\n\n					<div class=\"range--handle\">\n						<div class=\"score-wrapper\">\n							<span class=\"score\" data-container=\"subtotal-score\">"
    + alias4(((helper = (helper = helpers.total_score || (depth0 != null ? depth0.total_score : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"total_score","hash":{},"data":data}) : helper)))
    + "</span>\n							<span class=\"slash\"></span>\n							<span class=\"possible\">"
    + alias4(((helper = (helper = helpers.points_possible || (depth0 != null ? depth0.points_possible : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"points_possible","hash":{},"data":data}) : helper)))
    + "</span>\n						</div>\n					</div>\n				</div>\n			</div>\n		</li>\n		<li data-behavior=\"total\">\n			<div class=\"range-tertiary range-editable range-total\">\n				<div class=\"range--track-wrapper\">\n					<h4 class=\"range-total--label\">Weighted Score</h4>\n\n					<div class=\"range--handle\">\n						<div class=\"score-wrapper\">\n							<span class=\"score\" data-container=\"total-score\">"
    + alias4(((helper = (helper = helpers.total_score || (depth0 != null ? depth0.total_score : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"total_score","hash":{},"data":data}) : helper)))
    + "</span>\n							<span class=\"slash\"></span>\n							<span class=\"possible\">"
    + alias4(((helper = (helper = helpers.points_possible || (depth0 != null ? depth0.points_possible : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"points_possible","hash":{},"data":data}) : helper)))
    + "</span>\n						</div>\n					</div>\n				</div>\n			</div>\n		</li>\n		<li>\n			<div class=\"range-tertiary range-editable range-total\">\n				<div class=\"range--track-wrapper\">\n					<h4 class=\"range-total--label\">Visible To Student?</h4>\n\n					<div class=\"range-total--value\">\n						<div class=\"switch "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.published : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n							<input data-behavior=\"publish-switch\" id=\"checkbox-"
    + alias4(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data}) : helper)))
    + "\"\n								   "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.published : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " class=\"switch-checkbox\" type=\"checkbox\">\n							<label for=\"checkbox-"
    + alias4(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data}) : helper)))
    + "\" class=\"switch-label\">\n						    <span class=\"switch-inner\">\n						      <span class=\"switch-text switch-active\">Yes</span>\n						      <span class=\"switch-text switch-inactive\">No</span>\n						    </span>\n							</label>\n						</div>\n					</div>\n				</div>\n			</div>\n		</li>\n\n\n		<li data-behavior=\"utility\" class=\"evaluation-single--utility\">\n			<div>\n				<a data-behavior=\"evaluation-save\" class=\"primary-button update-button\" href=\"#\">Save Evaluation</a>\n				<a data-behavior=\"evaluation-destroy\" class=\"gentle-warning-button delete-button\" href=\"#\">Delete\n					Evaluation</a>\n			</div>\n\n			<div style=\"margin-top: 20px; display: none\" data-behavior=\"notice\" class=\"alert alert-notice\">\n			</div>\n\n		</li>\n	</ul>\n\n\n</li>\n";
},"useData":true});