var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "			  <span style=\"display: none;\" data-behavior=\"range-placard\" class=\"placard\">\n				<span class=\"placard--header\">"
    + alias2(alias1((depth0 != null ? depth0.placard_title : depth0), depth0))
    + "</span>\n			  <span class=\"placard--content\">\n				  "
    + alias2(alias1((depth0 != null ? depth0.placard_desc : depth0), depth0))
    + "\n			  </span>\n		  </span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "			<div class=\"range--handle\"><b></b>"
    + alias2(alias1((depth0 != null ? depth0.score : depth0), depth0))
    + "/"
    + alias2(alias1((depth0 != null ? depth0.possible : depth0), depth0))
    + "</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "			<div class=\"range--handle\"><b></b>"
    + container.escapeExpression(__default(require("../../helpers/to_i.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.percentage : depth0),{"name":"to_i","hash":{},"data":data}))
    + "%</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div data-behavior=\"range\" class=\"range "
    + alias2(alias1((depth0 != null ? depth0.range_class : depth0), depth0))
    + "\">\n	<h4 class=\"range--title\">\n		"
    + alias2(__default(require("../../helpers/titlecase.js")).call(alias3,(depth0 != null ? depth0.title : depth0),{"name":"titlecase","hash":{},"data":data}))
    + "\n	</h4>\n\n	<div class=\"range--track\">\n	  <span style=\"width: "
    + alias2(alias1((depth0 != null ? depth0.percentage : depth0), depth0))
    + "%\" class=\"range--fill\">\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.placard : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	  </span>\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.score : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "	</div>\n</div>\n\n\n\n";
},"useData":true});