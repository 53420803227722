var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <div class=\"body\">\n            <div style=\"margin-top: 0px\" data-region=\"flash\"></div>\n            <form class=\"formgrid new-rubric-form\">\n                <fieldset>\n                    <div class=\"formgrid whole rubric-name\">\n                        <input type=\"text\" value=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" data-behavior=\"rubric-name\" placeholder=\"Name your rubric\">\n                    </div>\n                    <div class=\"formgrid whole\">\n                        <input type=\"text\" value=\""
    + alias4(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper)))
    + "\" data-behavior=\"rubric-desc\" placeholder=\"Enter a brief description of your rubric\">\n                    </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.current_user_is_admin : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </fieldset>\n            </form>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                        <div class=\"formgrid third\">\n                            <span style=\"display: inline-block; margin-right: 5px; vertical-align: middle;\">Is this a system wide rubric?</span>\n                            <div style=\"display: inline-block; vertical-align: middle;\" class=\"switch "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0["public"] : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-region=\"rubric-public\">\n                                <input id=\"checkbox\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0["public"] : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " class=\"switch-checkbox\" type=\"checkbox\">\n                                <label for=\"checkbox\" class=\"switch-label\">\n                                    <span class=\"switch-inner\">\n                                      <span class=\"switch-text switch-active\">Yes</span>\n                                      <span class=\"switch-text switch-inactive\">No</span>\n                                    </span>\n                                </label>\n                            </div>\n                        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "switch-checked";
},"5":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <div class=\"header-wrapper\">\n            <div style=\"margin: 5px 0\" data-region=\"flash\"></div>\n            <div>\n                <h2 class=\"heading-primary editable-field\" data-trigger=\"edit-attribute\" data-label=\"name\">\n                    "
    + container.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\n                </h2>\n            </div>\n            <div>\n                <p class=\"editable-field\" style=\"font-size: 1.125em; margin-bottom: 10px;\" data-trigger=\"edit-attribute\" data-label=\"description\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.description : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "                </p>\n            </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.current_user_is_admin : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                        "
    + container.escapeExpression(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"description","hash":{},"data":data}) : helper)))
    + "\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "                        Enter a brief description of your rubric\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                <div>\n                    <span style=\"display: inline-block; margin-right: 5px; vertical-align: middle;\">Is this a system wide rubric?</span>\n                    <div style=\"display: inline-block; vertical-align: middle;\" class=\"switch "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0["public"] : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-region=\"rubric-public\">\n                        <input id=\"checkbox\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0["public"] : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " class=\"switch-checkbox\" type=\"checkbox\">\n                        <label for=\"checkbox\" class=\"switch-label\">\n                            <span class=\"switch-inner\">\n                              <span class=\"switch-text switch-active\">Yes</span>\n                              <span class=\"switch-text switch-inactive\">No</span>\n                            </span>\n                        </label>\n                    </div>\n                </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"page-section\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.new_record : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n\n<div class=\"page-section page-section-clear\">\n	<div class=\"body\">\n		<div class=\"rubric-builder\" data-region=\"rubric-builder\"></div>\n\n        <div class=\"rubric-buttons\">\n            <!--\n              <a href=\"#\" data-trigger=\"recalc\" class=\"primary-button\">Recalculate Matrix</a>\n              -->\n            <div style=\"text-align: left; display: inline-block; width: 49%;\">\n                <a href=\"#\" data-trigger=\"save\" class=\"primary-button update-button\">Save rubric</a>\n                <a href=\"#\" data-trigger=\"cancel\" class=\"warning-button cancel-button\">Cancel</a>\n            </div>\n            <div style=\"text-align: right; display: inline-block; width: 49%;\">\n                <a href=\"#\" data-trigger=\"scoring-modal\" class=\"primary-button customize-button\">Customize Scoring</a>\n            </div>\n        </div>\n	</div>\n</div>\n\n\n";
},"useData":true});