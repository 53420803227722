var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "two-thirds";
},"3":function(container,depth0,helpers,partials,data) {
    return "whole";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "			<div class=\"embed-watermark\">\n				<img alt=\"VOCAT\" src=\"/static_assets/images/logo.png\" />\n			</div>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.authorized_user : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "			<div class=\"asset-overlay-trigger\">\n				<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"currentColor\" class=\"bi bi-info-circle\"\n					viewBox=\"0 0 16 16\">\n					<path d=\"M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z\" />\n					<path\n						d=\"m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z\" />\n				</svg>\n			</div>\n			<div class=\"asset-overlay\">\n				<div>\n					<table>\n						<tr>\n							<td><b>Organization:</b></td>\n							<td>"
    + alias4(((helper = (helper = helpers.organization_name || (depth0 != null ? depth0.organization_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"organization_name","hash":{},"data":data}) : helper)))
    + "</td>\n						</tr>\n						<tr>\n							<td><b>Course:</b></td>\n							<td>"
    + alias4(((helper = (helper = helpers.course_name || (depth0 != null ? depth0.course_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"course_name","hash":{},"data":data}) : helper)))
    + "</td>\n						</tr>\n						<tr>\n							<td><b>Project:</b></td>\n							<td>"
    + alias4(((helper = (helper = helpers.project_name || (depth0 != null ? depth0.project_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"project_name","hash":{},"data":data}) : helper)))
    + "</td>\n						</tr>\n						<tr>\n							<td><b>Asset name:</b></td>\n							<td>"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</td>\n						</tr>\n						<tr>\n							<td><b>Uploaded by:</b></td>\n							<td>"
    + alias4(((helper = (helper = helpers.creator_name || (depth0 != null ? depth0.creator_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"creator_name","hash":{},"data":data}) : helper)))
    + "</td>\n						</tr>\n						<tr>\n							<td><b>Uploaded at:</b></td>\n							<td>"
    + alias4(((helper = (helper = helpers.created_at || (depth0 != null ? depth0.created_at : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"created_at","hash":{},"data":data}) : helper)))
    + "</td>\n						</tr>\n						<tr>\n							<td><b>Asset type:</b></td>\n							<td>"
    + alias4(((helper = (helper = helpers.file_info || (depth0 != null ? depth0.file_info : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"file_info","hash":{},"data":data}) : helper)))
    + "</td>\n						</tr>\n						<tr>\n							<td><b>Vocat Link:</b></td>\n							<td>"
    + alias4(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data}) : helper)))
    + "</td>\n						</tr>\n					</table>\n				</div>\n			</div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "		<button data-behavior=\"show-embed-help\" class=\"primary-button\" style=\"float: right;\">\n			<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"currentColor\" class=\"bi bi-pip\" viewBox=\"0 0 16 16\">\n			  <path d=\"M0 3.5A1.5 1.5 0 0 1 1.5 2h13A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5v-9zM1.5 3a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z\"/>\n			  <path d=\"M8 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-5a.5.5 0 0 1-.5-.5v-3z\"/>\n			</svg>\n		</button>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "	<div data-behavior=\"annotations-column\"\n		 class=\"page-section page-section-one-third col-two "
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.hasDuration : depth0),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"\n		 data-region=\"annotations\">\n	</div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "annotations-column-scrollable";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"page-section-wrapper asset-detail-layout asset-detail-"
    + container.escapeExpression(((helper = (helper = helpers.family || (depth0 != null ? depth0.family : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"family","hash":{},"data":data}) : helper)))
    + "\">\n	<div data-behavior=\"player-column\" class=\"page-section page-section-"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showAnnotations : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + " col-one\">\n		<div class=\"asset-player-container\" style=\"position: relative; overflow: hidden;\">\n			<div data-region=\"annotations-stage\"></div>\n			<div data-region=\"annotator-canvas\"></div>\n			<div class=\"asset-player\" data-region=\"player\"></div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.embedded : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n		<div data-region=\"annotator\"></div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showEmbedDialog : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	</div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showAnnotations : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});