var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                <i class=\"icon people\"></i>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <div class=\"due-date\">\n                    Due "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.project : depth0)) != null ? stack1.due_date : stack1), depth0))
    + " %>\n                </div>\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.submission : depth0)) != null ? stack1.assets : stack1),{"name":"each","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "        <div class=\"portfolio-item--thumb\">\n            <div class=\"thumb-container thumb-container-video\">\n                <a href=\""
    + alias2(alias1(((stack1 = (depths[1] != null ? depths[1].submission : depths[1])) != null ? stack1.path : stack1), depth0))
    + "\" data-behavior=\"open-submission-detail\">\n                    <img src=\""
    + alias2(alias1((depth0 != null ? depth0.thumbnail : depth0), depth0))
    + "\"/>\n                </a>\n            </div>\n        </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"portfolio-item--thumb\">\n            <div class=\"thumb-container-placeholder\">\n                <a href=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.submission : depth0)) != null ? stack1.path : stack1), depth0))
    + "\">\n                    <span>Awaiting Attachment Upload</span>\n                </a>\n            </div>\n        </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <div class=\"footer-item\">\n            <i class=\"instructor-icon\"></i>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.submission : depth0)) != null ? stack1.evaluated_by_instructor : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "")
    + "            <div class=\"labels\">\n                <span class=\"icon-label\">Instructor Score</span>\n            </div>\n        </div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.submission : depth0)) != null ? stack1.project : stack1)) != null ? stack1.evaluatable_by_peers : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <span class=\"icon-value\">"
    + container.escapeExpression(__default(require("../../helpers/to_i.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.submission : depth0)) != null ? stack1.instructor_score_percentage : stack1),{"name":"to_i","hash":{},"data":data}))
    + "%</span>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "                <span class=\"icon-value no-value\">&dash;&dash;</span>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <div class=\"footer-item\">\n                <i class=\"peer-icon\"></i>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.submission : depth0)) != null ? stack1.evaluated_by_peers : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data})) != null ? stack1 : "")
    + "                <div class=\"labels\">\n                    <span class=\"icon-label\">Average Peer Score</span>\n                </div>\n            </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <span class=\"icon-value\">"
    + container.escapeExpression(__default(require("../../helpers/to_i.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.submission : depth0)) != null ? stack1.peer_score_percentage : stack1),{"name":"to_i","hash":{},"data":data}))
    + "%</span>\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "                    <span class=\"icon-value no-value\">&dash;&dash;</span>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <span class=\"icon-value\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.submission : depth0)) != null ? stack1.discussion_posts_count : stack1), depth0))
    + "</span>\n";
},"22":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"icon-value no-value\">&dash;&dash;</span>\n";
},"24":function(container,depth0,helpers,partials,data) {
    return "                <span class=\"new-label\">NEW</span>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <span class=\"icon-value\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.submission : depth0)) != null ? stack1.annotations_count : stack1), depth0))
    + "</span>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"padded-container\">\n    <div class=\"portfolio-item--content\">\n        <h3 class=\"project-name-header\" style=\"font-family: 'proxima-nova-condensed'; font-weight: 400; margin-top: 0; text-transform: none; font-size: 26px; letter-spacing: normal;\">\n            <a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.submission : depth0)) != null ? stack1.path : stack1), depth0))
    + "\" data-behavior=\"open-submission-detail\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.submission : depth0)) != null ? stack1.project : stack1)) != null ? stack1.name : stack1), depth0))
    + "</a>\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.isGroupProject : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.project : depth0)) != null ? stack1.due_date : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </h3>\n    </div>\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.submission : depth0)) != null ? stack1.has_asset : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.program(8, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "</div>\n<div class=\"submission-footer info-container padded-container\">\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.submission : depth0)) != null ? stack1.project : stack1)) != null ? stack1.evaluatable : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <div class=\"footer-item\">\n        <i class=\"comments-icon-teal\"></i>\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.submission : depth0)) != null ? stack1.discussion_posts_count : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.program(22, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "        <div class=\"labels\">\n            <span class=\"icon-label\">Comments</span>\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.submission : depth0)) != null ? stack1.new_posts_for_current_user : stack1),{"name":"if","hash":{},"fn":container.program(24, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n    </div>\n\n    <div class=\"footer-item\">\n        <i class=\"annotations-icon-teal\"></i>\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.submission : depth0)) != null ? stack1.annotations_count : stack1),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.program(22, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "        <div class=\"labels\">\n            <span class=\"icon-label\">Annotations</span>\n        </div>\n    </div>\n</div>\n";
},"useData":true,"useDepths":true});