var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.assets : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "            <div class=\"media-submission\" data-region=\"media-submission\" data-id=\""
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "\">\n                <div class=\"media-attributes\">\n                    <img src=\""
    + alias2(alias1((depth0 != null ? depth0.thumbnail : depth0), depth0))
    + "\"/>\n                    <div>\n                        <p>"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "</p>\n                        <span>Uploaded "
    + alias2(alias1((depth0 != null ? depth0.short_created_at : depth0), depth0))
    + "</span>\n                    </div>\n                </div>\n                <div class=\"media-counts\">\n                    <i class=\"annotation-count\"></i>\n                    <span>"
    + alias2(alias1((depth0 != null ? depth0.annotations_count : depth0), depth0))
    + "</span>\n                </div>\n            </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "        No media uploaded\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper;

  return "            "
    + container.escapeExpression(((helper = (helper = helpers.current_user_percentage || (depth0 != null ? depth0.current_user_percentage : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"current_user_percentage","hash":{},"data":data}) : helper)))
    + "%\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "            &mdash;\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper;

  return "            "
    + container.escapeExpression(((helper = (helper = helpers.peer_score_percentage || (depth0 != null ? depth0.peer_score_percentage : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"peer_score_percentage","hash":{},"data":data}) : helper)))
    + "%\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper;

  return "            "
    + container.escapeExpression(((helper = (helper = helpers.self_evaluation_percentage || (depth0 != null ? depth0.self_evaluation_percentage : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"self_evaluation_percentage","hash":{},"data":data}) : helper)))
    + "%\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return "<td class=\"student-name\">\n    <span>\n        "
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.creator : depth0)) != null ? stack1.name : stack1), depth0))
    + "\n    </span>\n</td>\n<td class=\"media-submission-list\">\n"
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.assets : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</td>\n<td class=\"number-cell narrow-cell\">\n    <span>"
    + alias1(((helper = (helper = helpers.discussion_posts_count || (depth0 != null ? depth0.discussion_posts_count : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias2,{"name":"discussion_posts_count","hash":{},"data":data}) : helper)))
    + "</span>\n</td>\n<td class=\"number-cell\">\n    <span>\n"
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.current_user_percentage : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "    </span>\n</td>\n<td class=\"number-cell gray\">\n    <span>\n"
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.peer_score_percentage : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "    </span>\n</td>\n<td class=\"number-cell gray\">\n    <span>\n"
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.self_evaluation_percentage : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "    </span>\n</td>\n";
},"useData":true});