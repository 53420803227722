var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "		<dl class=\"cell--media\">\n			<dt>Media</dt>\n			<dd>\n                "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.has_asset : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "			</dd>\n		</dl>\n		"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.can_be_evaluated : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(21, data, 0),"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return " <!-- does the submission have any assets? -->\n                    <b class=\"matrix--checkbox checked\"></b>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                    <b class=\"matrix--checkbox \"></b>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return " <!-- can the submission be evaluated? (does the project have a rubric?) -->\n			<dl class=\"cell--published\">\n				<dt>Published</dt>\n                <dd class=\"switch "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.current_user_published : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.current_user_percentage : depth0),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-behavior=\"publish-toggle\">\n                    <input id=\"checkbox-"
    + alias3(alias2((depth0 != null ? depth0.cid : depth0), depth0))
    + "\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.current_user_published : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " class=\"switch-checkbox\" type=\"checkbox\">\n                    <label for=\"checkbox-"
    + alias3(alias2((depth0 != null ? depth0.cid : depth0), depth0))
    + "\" class=\"switch-label\">\n                        <span class=\"switch-inner\">\n                          <span class=\"switch-text switch-active\">Yes</span>\n                          <span class=\"switch-text switch-inactive\">No</span>\n                        </span>\n                    </label>\n                </dd>\n            </dl>\n            "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.current_user_percentage : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data})) != null ? stack1 : "")
    + "            "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.instructor_score_percentage : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    return "switch-checked";
},"9":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"11":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"13":function(container,depth0,helpers,partials,data) {
    return " <!-- has the current user evaluated the submission? -->\n                <span class=\"cell--message cell--message-pinned\" >SCORE: <span style=\"color: #414141\">"
    + container.escapeExpression(__default(require("../../helpers/to_i.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.current_user_percentage : depth0),{"name":"to_i","hash":{},"data":data}))
    + "%</span></span>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "                <span class=\"cell--message cell--message-pinned\" >SCORE: <span style=\"color: #414141\">&mdash;</span></span>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return " <!-- is there an instructor average score? -->\n                <span class=\"cell--message cell--message-pinned\" style=\"right: 0;\">AVERAGE: <span style=\"color: #414141\">"
    + container.escapeExpression(__default(require("../../helpers/to_i.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.instructor_score_percentage : depth0),{"name":"to_i","hash":{},"data":data}))
    + "%</span></span>\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "                <span class=\"cell--message cell--message-pinned\" style=\"right: 0;\">AVERAGE: <span style=\"color: #414141\">&mdash;</span></span>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "            <dl class=\"cell--published\">\n                <dt>Feedback</dt>\n                <dd>\n                    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.user_left_feedback : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(24, data, 0),"data":data})) != null ? stack1 : "")
    + "                </dd>\n            </dl>\n            "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.comments_count : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.program(28, data, 0),"data":data})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    return " <!-- has the current user has annotated or made a discussion post on the submission? -->\n                        <b class=\"matrix--checkbox checked\"></b>\n";
},"24":function(container,depth0,helpers,partials,data) {
    return "                        <b class=\"matrix--checkbox \"></b>\n";
},"26":function(container,depth0,helpers,partials,data) {
    return "  <!-- are there discussion posts on the submission? -->\n                <span class=\"cell--message cell--message-pinned\" style=\"width: 100%\">COMMENTS: <span style=\"color: #414141\">"
    + container.escapeExpression(__default(require("../../helpers/to_i.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.comments_count : depth0),{"name":"to_i","hash":{},"data":data}))
    + "</span></span>\n";
},"28":function(container,depth0,helpers,partials,data) {
    return "                <span class=\"cell--message cell--message-pinned\" style=\"width: 100%\">COMMENTS: <span style=\"color: #414141\">&mdash;</span></span>\n";
},"30":function(container,depth0,helpers,partials,data) {
    return "		<span class=\"cell--message\">Loading...</span>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"cell--container\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.is_loaded : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(30, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});