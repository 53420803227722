var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "	<a class=\"matrix--column-header--link\" data-behavior=\"project-title\" href=\"#\"><span>"
    + container.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data}) : helper)))
    + "</span></a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "	<span>"
    + container.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data}) : helper)))
    + "</span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "					<li><a href=\"/courses/"
    + alias4(((helper = (helper = helpers.courseId || (depth0 != null ? depth0.courseId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"courseId","hash":{},"data":data}) : helper)))
    + "/manage/projects/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "/edit\">Edit Project Settings</a></li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.userCanAdministerCourse : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\n<div>\n    <!--\n        NB: Conditional Dropdown bump provides ample spacing so that dropdown can always be\n        placed at the bottom of a table header\n    -->\n    <div class=\"dropdown--bump\"></div>\n	<div class=\"dropdown dropdown-inline\" data-behavior=\"dropdown\">\n		<a class=\"dropdown--toggle\" data-behavior=\"toggle\" href=\"#\">OPTIONS</a>\n\n		<div class=\"dropdown--options\" data-behavior=\"dropdown-options\">\n			<ul>\n				<li><a href=\"#\" data-behavior=\"publish-all\">Publish All Evaluations</a></li>\n				<li><a href=\"#\" data-behavior=\"unpublish-all\">Unpublish All Evaluations</a></li>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.userCanAdministerCourse : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			</ul>\n		</div>\n	</div>\n</div>\n";
},"useData":true});