var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "seek";
},"3":function(container,depth0,helpers,partials,data) {
    return "activate";
},"5":function(container,depth0,helpers,partials,data) {
    return "					<span class=\"annotation-drawing-indicator\"></span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper;

  return "					<span data-behavior=\"timecode\">"
    + container.escapeExpression(((helper = (helper = helpers.smpte_timecode || (depth0 != null ? depth0.smpte_timecode : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"smpte_timecode","hash":{},"data":data}) : helper)))
    + "</span><br/>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper;

  return "					<span>"
    + container.escapeExpression(((helper = (helper = helpers.created_at || (depth0 != null ? depth0.created_at : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"created_at","hash":{},"data":data}) : helper)))
    + "</span>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "				<a href=\"#\" class=\"annotation-edit-link\" data-behavior=\"edit\"></a>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "				<a href=\"#\" class=\"annotation-delete-link\" data-behavior=\"destroy\"></a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div data-behavior=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.assetHasDuration : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\" class=\"annotation-wrapper\">\n	<div class=\"annotation-left\">\n		<div class=\"annotation-top\">\n			<div class=\"annotation-creator role-"
    + alias4(((helper = (helper = helpers.author_role || (depth0 != null ? depth0.author_role : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"author_role","hash":{},"data":data}) : helper)))
    + "\">\n				"
    + alias4(((helper = (helper = helpers.author_name || (depth0 != null ? depth0.author_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"author_name","hash":{},"data":data}) : helper)))
    + "\n			</div>\n			<div class=\"annotation-timestamp\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasDrawing : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.assetHasDuration : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "			</div>\n		</div>\n		<div class=\"annotation-bottom markdown-content\" data-behavior=\"annotation-body\">\n			"
    + ((stack1 = ((helper = (helper = helpers.body || (depth0 != null ? depth0.body : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"body","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n		</div>\n\n	</div>\n	<div class=\"annotation-right\">\n		<img src=\""
    + alias4(((helper = (helper = helpers.gravatar || (depth0 != null ? depth0.gravatar : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"gravatar","hash":{},"data":data}) : helper)))
    + "\"/>\n\n		<div class=\"annotation-utility\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.current_user_can_edit : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.current_user_can_destroy : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n	</div>\n</div>";
},"useData":true});