var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"annotation\">\n	<div class=\"annotation-wrapper\">\n		<div class=\"annotation-left\">\n			<img src=\""
    + alias4(((helper = (helper = helpers.gravatar || (depth0 != null ? depth0.gravatar : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"gravatar","hash":{},"data":data}) : helper)))
    + "\" />\n		</div>\n		<div class=\"annotation-right\">\n			<div class=\"annotation-top\">\n				<div class=\"annotation-creator role-"
    + alias4(((helper = (helper = helpers.author_role || (depth0 != null ? depth0.author_role : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"author_role","hash":{},"data":data}) : helper)))
    + "\">\n					"
    + alias4(((helper = (helper = helpers.author_name || (depth0 != null ? depth0.author_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"author_name","hash":{},"data":data}) : helper)))
    + "\n				</div>\n				<div class=\"annotation-timestamp\">\n					<span data-behavior=\"timecode\">"
    + alias4(((helper = (helper = helpers.smpte_timecode || (depth0 != null ? depth0.smpte_timecode : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"smpte_timecode","hash":{},"data":data}) : helper)))
    + "</span><br />\n				</div>\n			</div>\n			<div class=\"annotation-bottom markdown-content\" data-behavior=\"annotation-body\">\n				"
    + ((stack1 = ((helper = (helper = helpers.body || (depth0 != null ? depth0.body : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"body","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n			</div>\n		</div>\n	</div>\n</div>\n";
},"useData":true});