var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda;

  return "<div data-region=\"range-header\" class=\"range-header\" style=\"background-color: "
    + alias2(__default(require("../../helpers/gradient.js")).call(alias1,"#80d3cf","#069a91",(depth0 != null ? depth0.percentage : depth0),{"name":"gradient","hash":{},"data":data}))
    + "\">\n    <span class=\"cell-control edit\" data-behavior=\"edit\"><i class=\"edit-icon\"></i></span>\n    <span class=\"cell-control move-left\" data-behavior=\"move-left\"><i class=\"left-icon\"></i></span>\n    <span class=\"cell-control move-right\" data-behavior=\"move-right\"><i class=\"right-icon\"></i></span>\n    <span class=\"cell-control destroy\" data-behavior=\"destroy\"><i class=\"delete-icon\"></i></span>\n    <div class=\"range-name\">"
    + alias2(alias3((depth0 != null ? depth0.name : depth0), depth0))
    + "</div>\n    <div class=\"range-score\">"
    + alias2(alias3((depth0 != null ? depth0.high : depth0), depth0))
    + "</div>\n</div>\n<div data-id=\"range-cells\" class=\"range-cells\" style=\"background-color: "
    + alias2(__default(require("../../helpers/gradient.js")).call(alias1,"#ecf7f7","#cbe4e5",(depth0 != null ? depth0.percentage : depth0),{"name":"gradient","hash":{},"data":data}))
    + "\"></div>\n\n";
},"useData":true});