var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "		<div class=\"buttons\">\n			<a href=\"#\" data-behavior=\"manage-link\" class=\"primary-button\">Manage Media</a>\n			<a href=\"#\" data-behavior=\"stop-manage-link\" class=\"secondary-button-dark confirm-button\">Done Managing\n				Media</a>\n		</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <label class=\"text-label\" for=\"user_project_Project Media\" style=\"display:inline-block;margin-top:30px\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.assetableIsProject : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.assetableIsSubmission : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </label>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "            Project Media\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "            Submission Media\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <h2>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.assetableIsProject : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.assetableIsSubmission : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </h2>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "style=\"padding:0.5em 0;margin-top:0;border-top:1px solid #9d9fa2\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"page-section page-section-clear page-section-spacing-top assets-container\">\n	<div class=\"header-wrapper\">\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isProjectForm : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		<div data-behavior=\"detail-header\" class=\"header header-closeable\">\n			<h2><a href=\"#\" data-behavior=\"close-link\" class=\"close-link\"><span data-behavior=\"detail-header-content\">&nbsp;</span></a>\n			</h2>\n		</div>\n		<div data-behavior=\"asset-collection-header\" class=\"header\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isInlineInForm : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "		</div>\n	</div>\n	<div class=\"body\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isInlineInForm : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n		<div data-region=\"asset-new\" class=\"asset-new-container\"></div>\n		<div data-region=\"asset-collection\" class=\"asset-collection-container\"></div>\n		<div data-region=\"asset-new-footer\" class=\"asset-new-footer-container\"></div>\n	</div>\n</div>\n";
},"useData":true});