var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <span class=\"badge\">Due "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.project : depth0)) != null ? stack1.due_date : stack1), depth0))
    + "</span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                    <a href=\"#\" class=\"button primary-button\" data-behavior=\"show-rubric\">Show Rubric</a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"submissions-list\">\n            <div class=\"header-wrapper\">\n                <div class=\"header student-submissions-list\">\n                    <h2>Students</h2>\n                </div>\n            </div>\n            <div class=\"body\">\n                <div class=\"table-wrapper\" data-region=\"project-student-submission-list\"></div>\n            </div>\n        </div>\n        <div class=\"submissions-list\">\n            <div class=\"header-wrapper\">\n                <div class=\"header group-submissions-list\">\n                    <h2>Groups</h2>\n                </div>\n            </div>\n            <div class=\"body\">\n                <div class=\"table-wrapper\" data-region=\"project-group-submission-list\"></div>\n            </div>\n        </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"submissions-list\">\n            <div class=\"header-wrapper\">\n                <div class=\"header student-submissions-list\">\n                    <h2>Students</h2>\n                </div>\n            </div>\n            <div class=\"body\">\n                <div class=\"table-wrapper\" data-region=\"project-student-submission-list\"></div>\n            </div>\n        </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"submissions-list\">\n            <div class=\"header-wrapper\">\n                <div class=\"header group-submissions-list\">\n                    <h2>Groups</h2>\n                </div>\n            </div>\n            <div class=\"body\">\n                <div class=\"table-wrapper\" data-region=\"project-group-submission-list\"></div>\n            </div>\n        </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"page-section project-overview\">\n	<div class=\"header-wrapper\">\n        <div class=\"header\">\n            <p style=\"color: rgb(140, 140, 140);\">PROJECT</p>\n            <h1 class=\"project-name-header\" style=\"font-weight: 400; margin-bottom: 10px; text-transform: none;\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.project : depth0)) != null ? stack1.name : stack1), depth0))
    + "</h1>\n            "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? depth0.project : depth0)) != null ? stack1.description : stack1), depth0)) != null ? stack1 : "")
    + "\n            <div class=\"heading-utility\" style=\"margin-top: 1em; margin-bottom: 3em;\">\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.project : depth0)) != null ? stack1.due_date : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.project : depth0)) != null ? stack1.rubric_id : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                <a href=\""
    + alias2(alias1((depth0 != null ? depth0.url : depth0), depth0))
    + "/courses/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.project : depth0)) != null ? stack1.course_id : stack1), depth0))
    + "/manage/projects/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.project : depth0)) != null ? stack1.id : stack1), depth0))
    + "/edit\" class=\"button primary-button\">Edit Project Settings</a>\n            </div>\n        </div>\n    </div>\n    <div class=\"header\">\n        <h2 class=\"section-header\" style=\"margin-bottom: 10px;\">PROJECT OVERVIEW</h2>\n    </div>\n	<div class=\"body\">\n		<div class=\"page-section--subsection\" data-region=\"project-score-overview\"></div>\n	</div>\n</div>\n\n<div class=\"page-section page-section-clear\">\n"
    + ((stack1 = __default(require("../../helpers/if_match.js")).call(alias3,((stack1 = (depth0 != null ? depth0.project : depth0)) != null ? stack1.type : stack1),"OpenProject",{"name":"if_match","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../helpers/if_match.js")).call(alias3,((stack1 = (depth0 != null ? depth0.project : depth0)) != null ? stack1.type : stack1),"UserProject",{"name":"if_match","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../helpers/if_match.js")).call(alias3,((stack1 = (depth0 != null ? depth0.project : depth0)) != null ? stack1.type : stack1),"GroupProject",{"name":"if_match","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n\n";
},"useData":true});