var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "	<a class=\"matrix--column-header--link\" href=\"/courses/"
    + container.escapeExpression(((helper = (helper = helpers.courseId || (depth0 != null ? depth0.courseId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"courseId","hash":{},"data":data}) : helper)))
    + "/manage/projects/new\">\n		<span>Create a new project</span>\n	</a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "	No projects have been created. Check back soon.\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isCreator : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});