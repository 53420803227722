var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "		<button data-behavior=\"annotation-update\" class=\"primary-button new-button\">Update</button>\n		<button data-behavior=\"annotation-edit-cancel\" class=\"gentle-warning-button cancel-button\">Cancel</button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "		<button data-behavior=\"annotation-create\" class=\"primary-button new-button\">Post</button>\n		<button data-behavior=\"annotation-create-cancel\" class=\"warning-button cancel-button\">Cancel</button>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"formgrid whole\">\n	<textarea placeholder=\"To add an annotation, begin typing here or use the drawing tools below...\" class=\"annotation-input\"\n			  data-behavior=\"annotation-input\">"
    + container.escapeExpression(((helper = (helper = helpers.body_raw || (depth0 != null ? depth0.body_raw : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"body_raw","hash":{},"data":data}) : helper)))
    + "</textarea>\n</div>\n<div data-behavior=\"annotation-buttons-left\" class=\"formgrid third annotation-input-buttons-left\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.id : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n<div class=\"formgrid two-thirds border-buttons annotation-input-buttons-right\" data-region=\"annotator-input\">\n	<button class=\"primary-button select-button\" data-behavior=\"annotation-canvas-select\">Select</button>\n	<button class=\"primary-button erase-button\" data-behavior=\"annotation-canvas-erase\">Erase</button>\n	<button class=\"primary-button oval-button\" data-behavior=\"annotation-canvas-oval\">Oval</button>\n	<button class=\"primary-button draw-button\" data-behavior=\"annotation-canvas-draw\">Draw</button>\n</div>\n<div class=\"formgrid whole\" style=\"position: relative;\">\n	<div class=\"annotation-message\" data-behavior=\"message\"></div>\n</div>\n";
},"useData":true});