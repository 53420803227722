var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    return "					<li style=\"width: "
    + container.escapeExpression(container.lambda((depths[1] != null ? depths[1].tickWidth : depths[1]), depth0))
    + "%\"></li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div data-behavior=\"range\" class=\"range range-tertiary range-editable\">\n	<h4 class=\"range--title\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h4>\n\n	<div class=\"range--track-wrapper\">\n		<div data-behavior=\"range-track\" class=\"range--track\">\n			<ul class=\"range--ticks\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.ticks : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			</ul>\n			<span data-behavior=\"range-fill\" style=\"width: "
    + alias4(((helper = (helper = helpers.percentage || (depth0 != null ? depth0.percentage : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"percentage","hash":{},"data":data}) : helper)))
    + "%\" class=\"range--fill\"></span>\n\n			<div class=\"grabber-wrapper\">\n        <span data-behavior=\"range-grabber\" class=\"range--grabber\">\n				  <span style=\"display: none;\" data-behavior=\"placard\" class=\"placard\">\n						<span data-behavior=\"placard-header\" class=\"placard--header\">"
    + alias4(((helper = (helper = helpers.placard_title || (depth0 != null ? depth0.placard_title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"placard_title","hash":{},"data":data}) : helper)))
    + "</span>\n					  <span data-behavior=\"placard-content\" class=\"placard--content\">\n						  "
    + alias4(((helper = (helper = helpers.placard_desc || (depth0 != null ? depth0.placard_desc : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"placard_desc","hash":{},"data":data}) : helper)))
    + "\n					  </span>\n				  </span>\n        </span>\n			</div>\n		</div>\n		<div class=\"range--handle\">\n			<div class=\"score-wrapper\">\n				<span class=\"score\" data-behavior=\"score\">"
    + alias4(((helper = (helper = helpers.score || (depth0 != null ? depth0.score : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"score","hash":{},"data":data}) : helper)))
    + "</span>\n				<span class=\"slash\"></span>\n				<span class=\"possible\" data-behavior=\"possible\">"
    + alias4(((helper = (helper = helpers.high || (depth0 != null ? depth0.high : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"high","hash":{},"data":data}) : helper)))
    + "</span>\n			</div>\n		</div>\n	</div>\n</div>\n";
},"useData":true,"useDepths":true});