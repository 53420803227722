var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.submission : depth0)) != null ? stack1.current_user_has_evaluated : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "			<li class=\"frame-summary--evaluated\"\n				style=\"padding-right: "
    + alias2(__default(require("../../helpers/invert.js")).call(alias1,((stack1 = (depth0 != null ? depth0.submission : depth0)) != null ? stack1.current_user_percentage : stack1),{"name":"invert","hash":{},"data":data}))
    + "%\">\n				<h4 class=\"frame-summary--score--title\">Self-Evaluation</h4>\n				<span class=\"frame-summary--score--value\">"
    + alias2(__default(require("../../helpers/to_i.js")).call(alias1,((stack1 = (depth0 != null ? depth0.submission : depth0)) != null ? stack1.current_user_percentage : stack1),{"name":"to_i","hash":{},"data":data}))
    + "\n					<small>%</small></span>\n			</li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "			<li class=\"frame-summary--unevaluated\">\n				<h4 class=\"frame-summary--score--title\">Self-Evaluation</h4>\n				<span class=\"frame-summary--score--value\">&ndash;&ndash;&nbsp;&nbsp;</span>\n			</li>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.submission : depth0)) != null ? stack1.peer_score_percentage : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "			<li class=\"frame-summary--evaluated\" style=\"padding-right: "
    + alias2(__default(require("../../helpers/invert.js")).call(alias1,((stack1 = (depth0 != null ? depth0.submission : depth0)) != null ? stack1.peer_score_percentage : stack1),{"name":"invert","hash":{},"data":data}))
    + "%\">\n				<h4 class=\"frame-summary--score--title\">Peer Evaluations</h4>\n				<span class=\"frame-summary--score--value\">"
    + alias2(__default(require("../../helpers/to_i.js")).call(alias1,((stack1 = (depth0 != null ? depth0.submission : depth0)) != null ? stack1.peer_score_percentage : stack1),{"name":"to_i","hash":{},"data":data}))
    + "\n					<small>%</small></span>\n			</li>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "			<li class=\"frame-summary--unevaluated\">\n				<h4 class=\"frame-summary--score--title\">Peer Evaluations</h4>\n				<span class=\"frame-summary--score--value\">&ndash;&ndash;&nbsp;&nbsp;</span>\n			</li>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "		<li class=\"frame-summary--evaluated\"\n			style=\"padding-right: "
    + alias2(__default(require("../../helpers/invert.js")).call(alias1,((stack1 = (depth0 != null ? depth0.submission : depth0)) != null ? stack1.instructor_score_percentage : stack1),{"name":"invert","hash":{},"data":data}))
    + "%\">\n			<h4 class=\"frame-summary--score--title\">Instructor Evaluations</h4>\n			<span class=\"frame-summary--score--value\">"
    + alias2(__default(require("../../helpers/to_i.js")).call(alias1,((stack1 = (depth0 != null ? depth0.submission : depth0)) != null ? stack1.instructor_score_percentage : stack1),{"name":"to_i","hash":{},"data":data}))
    + "\n				<small>%</small></span>\n		</li>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "		<li class=\"frame-summary--unevaluated\">\n			<h4 class=\"frame-summary--score--title\">Instructor Evaluations</h4>\n			<span class=\"frame-summary--score--value\">&ndash;&ndash;&nbsp;&nbsp;</span>\n		</li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<ul class=\"frame-summary--score-list\">\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.submission : depth0)) != null ? stack1.course_allows_self_evaluation : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.submission : depth0)) != null ? stack1.course_allows_peer_review : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.submission : depth0)) != null ? stack1.instructor_score_percentage : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "")
    + "\n\n</ul>\n";
},"useData":true});