var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isCreatorTypeGroup : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isCreatorTypeUser : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "			<p>The group evaluations grid is not available because no groups have been created for this course.</p>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.canUpdateCourse : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "				<p class=\"small\">Once you have created at least one group project and one group for the course, you will\n					be able to access\n					group submissions.\n				</p>\n\n				<p class=\"small\">\n					<a href=\"/courses/"
    + alias2(alias1((depth0 != null ? depth0.courseId : depth0), depth0))
    + "/manage/groups\" class=\"primary-button new-button\">Manage Course\n						Groups</a>\n					<a href=\"/courses/"
    + alias2(alias1((depth0 != null ? depth0.courseId : depth0), depth0))
    + "/users/evaluations\" class=\"secondary-button back-button\">Show\n						Individual Evaluations</a>\n				</p>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "			<p>The individual evaluations grid is not available because no students have been enrolled in this\n				course.</p>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.canUpdateCourse : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    return "				<p class=\"small\">Once you have added at least one student to the course, you will be able to access\n					student submissions.\n				</p>\n\n				<p class=\"small\">\n					<a href=\"/courses/"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.courseId : depth0), depth0))
    + "/manage/enrollment\" class=\"primary-button new-button\">Manage Course\n						Enrollment</a>\n				</p>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "		<p>The "
    + ((stack1 = __default(require("../../helpers/if_match.js")).call(alias1,(depth0 != null ? depth0.creatorType : depth0),"Group",{"name":"if_match","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = __default(require("../../helpers/if_match.js")).call(alias1,(depth0 != null ? depth0.creatorType : depth0),"User",{"name":"if_match","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n			evaluations grid is not available because no "
    + ((stack1 = __default(require("../../helpers/if_match.js")).call(alias1,(depth0 != null ? depth0.creatorType : depth0),"Group",{"name":"if_match","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n			"
    + ((stack1 = __default(require("../../helpers/if_match.js")).call(alias1,(depth0 != null ? depth0.creatorType : depth0),"User",{"name":"if_match","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " projects have been created for this course.</p>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.canUpdateCourse : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(22, data, 0),"data":data})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    return "group";
},"11":function(container,depth0,helpers,partials,data) {
    return "\n			individual";
},"13":function(container,depth0,helpers,partials,data) {
    return "individual";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "			<p class=\"small\">Once you have created at least one "
    + ((stack1 = __default(require("../../helpers/if_match.js")).call(alias1,(depth0 != null ? depth0.creatorType : depth0),"Group",{"name":"if_match","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n				"
    + ((stack1 = __default(require("../../helpers/if_match.js")).call(alias1,(depth0 != null ? depth0.creatorType : depth0),"User",{"name":"if_match","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " project, you will be able to access\n				"
    + ((stack1 = __default(require("../../helpers/if_match.js")).call(alias1,(depth0 != null ? depth0.creatorType : depth0),"Group",{"name":"if_match","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = __default(require("../../helpers/if_match.js")).call(alias1,(depth0 != null ? depth0.creatorType : depth0),"User",{"name":"if_match","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " submissions.\n			</p>\n\n			<p class=\"small\">\n"
    + ((stack1 = __default(require("../../helpers/if_match.js")).call(alias1,(depth0 != null ? depth0.creatorType : depth0),"Group",{"name":"if_match","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(20, data, 0),"data":data})) != null ? stack1 : "")
    + "			</p>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "\n					individual";
},"18":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "					<a href=\"/courses/"
    + alias2(alias1((depth0 != null ? depth0.courseId : depth0), depth0))
    + "/manage/projects/new\" class=\"primary-button new-button\">Create a Group\n						Project</a>\n					<a href=\"/courses/"
    + alias2(alias1((depth0 != null ? depth0.courseId : depth0), depth0))
    + "/users/evaluations\" class=\"secondary-button back-button\">Show\n						Individual Evaluations</a>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "					<a href=\"/courses/"
    + alias2(alias1((depth0 != null ? depth0.courseId : depth0), depth0))
    + "/manage/projects/new\" class=\"primary-button new-button\">Create an\n						Individual Project</a>\n					<a href=\"/courses/"
    + alias2(alias1((depth0 != null ? depth0.courseId : depth0), depth0))
    + "/groups/evaluations\" class=\"secondary-button back-button\">Go To Group\n						Evaluations</a>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "			<p class=\"small\">\n"
    + ((stack1 = __default(require("../../helpers/if_match.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.creatorType : depth0),"Group",{"name":"if_match","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data})) != null ? stack1 : "")
    + "			</p>\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "					<a href=\"/courses/"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.courseId : depth0), depth0))
    + "/users/evaluations\" class=\"secondary-button back-button\">Show\n						Individual Evaluations</a>\n";
},"25":function(container,depth0,helpers,partials,data) {
    return "					<a href=\"/courses/"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.courseId : depth0), depth0))
    + "/groups/evaluations\" class=\"secondary-button back-button\">Go To Group\n						Evaluations</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"alert notice\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isCreatorWarning : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isProjectWarning : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n\n";
},"useData":true});