var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "          This project accepts "
    + container.escapeExpression(__default(require("../../helpers/join.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.project : depth0)) != null ? stack1.allowed_attachment_families : stack1),{"name":"join","hash":{"capitalize":false},"data":data}))
    + " media.\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "              <div class=\"dark-text-input\">\n								<input type=\"text\" data-behavior=\"external-video-url\" placeholder=\"Vimeo / YouTube URL\" />\n								<a href=\"#\" data-behavior=\"external-video-url-submit\" class=\"primary-button new-button\">\n									Add\n								</a>\n              </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"page-section--component asset-upload\">\n\n	<div class=\"asset-uploading\" data-behavior=\"asset-uploading-message\" style=\"display: none\">\n		<span data-behavior=\"upload-status\" class=\"upload-status\">Uploading Asset</span>\n		<span data-behavior=\"upload-status-detail\" class=\"upload-status-detail\"></span>\n\n		<div class=\"progress-bar\">\n			<div data-behavior=\"progress-bar\" class=\"progress-bar--fill\"></div>\n		</div>\n	</div>\n\n	<div data-behavior=\"upload-form-wrapper\">\n		<form data-behavior=\"upload-form\" accept-charset=\"UTF-8\" action=\""
    + alias2(alias1((depth0 != null ? depth0.AWSS3Url : depth0), depth0))
    + "\"\n			  data-remote=\"true\" enctype=\"multipart/form-data\" id=\"video-upload\" method=\"post\">\n			<input type=\"hidden\" name=\"AWSAccessKeyId\" value=\""
    + alias2(alias1((depth0 != null ? depth0.AWSPublicKey : depth0), depth0))
    + "\"/>\n			<input type=\"hidden\" name=\"acl\" value=\"private\"/>\n			<input type=\"hidden\" name=\"success_action_status\" value=\"200\"/>\n			<input type=\"hidden\" name=\"key\"/>\n			<input type=\"hidden\" name=\"policy\"/>\n			<input type=\"hidden\" name=\"signature\"/>\n			<input style=\"display: none;\" data-behavior=\"file-input\" name=\"file\" type=\"file\"/>\n\n			<p class=\"instructions\">\n\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.project : depth0)) != null ? stack1.allowed_attachment_families : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        To add media\n				simply\n				drag and drop files into the area below (maximum file size: 5GB). You can also add media using your computer's file selection\n				dialog, or\n				add YouTube or Vimeo videos by simply entering the appropriate URL.\n			</p>\n\n			<div class=\"clearfix\">\n				<div class=\"asset-upload--column asset-upload--column-left\">\n					<div data-behavior=\"dropzone\" class=\"asset-upload--target\">\n						<a class=\"decorated\" data-behavior=\"file-picker\">Drag & Drop Files Here</a>\n					</div>\n				</div>\n				<div class=\"asset-upload--column asset-upload--column-right\">\n					<div class=\"dark-text-input\" data-behavior=\"file-input-trigger\">\n						<input disabled=\"disabled\" type=\"text\" placeholder=\"Select file\" />\n						<a href=\"#\" class=\"primary-button new-button\">Add</a>\n					</div>\n"
    + ((stack1 = __default(require("../../helpers/if_include.js")).call(alias3,((stack1 = (depth0 != null ? depth0.project : depth0)) != null ? stack1.allowed_attachment_families : stack1),"video",{"name":"if_include","hash":{"showIfNull":true},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "				</div>\n			</div>\n			<p class=\"bottom-instructions\">\n				Successfully uploaded media appears in the list below along with further editing and management options.\n			</p>\n		</form>\n	</div>\n</div>\n";
},"useData":true});