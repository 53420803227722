var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "	<div class=\"formgrid whole\">\n		<div class=\"alert alert-notice\">\n			<ul>\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.responseData : depth0)) != null ? stack1.successes : stack1),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			</ul>\n			<ul>\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.responseData : depth0)) != null ? stack1.failures : stack1),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			</ul>\n		</div>\n	</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "					<li>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"formgrid whole\">\n	<div data-region=\"confirm-invite\"></div>\n	<label>Bulk Add Users (<a href=\"#\" data-behavior=\"show-single\">or add one user at a time</a>)</label>\n	<textarea rows=\"10\" data-behavior=\"bulk-input\"></textarea>\n	<small class=\"input-message\">Enter one user per line. If LDAP is enabled and the user exists in LDAP, only the email\n		address is required. Otherwise, enter both the name and the email address\n		in the format of <em>name, email</em> or <em>name &lt;email&gt;</em>. For example, <em>Jane Doe &lt;jane@vocat.io&gt;</em>\n		and <em>Jane Doe, jane@vocat.io</em> are both acceptable entries.\n	</small>\n</div>\n<div class=\"formgrid whole\">\n	<a class=\"primary-button new-button\" href=\"#\" data-behavior=\"submit\">Add Users</a>\n</div>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.response : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});